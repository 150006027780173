import { render, staticRenderFns } from "./started.vue?vue&type=template&id=6d6c3c06&"
import script from "./started.vue?vue&type=script&lang=ts&"
export * from "./started.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports